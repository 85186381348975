import React from "react";
import { Modal } from "react-bootstrap";

const ThemeModal = ({
    modalContent,
    className,
    title,
    size,
    isOpen,
    setIsOpen,
    crossIcon
}) => {
    return (
        <Modal
            className={`theme-modal ${className}`}
            show={isOpen}
            onHide={() => setIsOpen(false)}
            size={size}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <header className="header d-flex align-items-center justify-content-between">
                <div className="heading-modal">
                    <h3 className="text-black fw-700">{title}</h3>
                </div>
            </header>
            <Modal.Body>{modalContent}</Modal.Body>
        </Modal>
    );
};

export default ThemeModal;