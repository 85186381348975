import React from "react";

function ImageCard({ imageUrl, question, options }) {
  return (
    <div className="w-100 image-guess-game bg-white rounded-3 p-3">
      <img
        style={{ height: "275px" }}
        alt=""
        src={imageUrl}
        width={"100%"}
        className="rounded-3 object-fit-contain image-fluid"
      />
      <hr></hr>
      <h5 className="m-0 d-flex">{question}</h5>
      <br />

      {!!options?.length &&
        options?.map((item, index) => (
          <div
            key={index}
            style={{
              backgroundColor: "#efefef",
              padding: "10px 30px",
              margin: "4px",
              borderRadius: "11px",
              display: "inline-block",
              width: "fit-content",
              fontSize: 20,
            }}
          >
            {item.option}
          </div>
        ))}
    </div>
  );
}

export default ImageCard;
