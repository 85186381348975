import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import ErrorPage from "../pages/ErrorPage";
import Games from "../pages/Games";
import LeaderBoard from "../pages/LeaderBoard";

const Navigations = () => {
  return (
    <Routes>
      <Route path="/" element={<Games />} />

      <Route path="/leaderboard" element={<LeaderBoard />} />

      <Route path="/spinwheel" element={<Dashboard />} />

      <Route
        path="*"
        element={<ErrorPage text={"There is no active game to play"} />}
      />
    </Routes>
  );
};

export default Navigations;

// return (
//   <Routes>
//     <Route
//       path="/"
//       element={
//         activeId == 1 && data?.playStatus === false && !loading ? (
//           <GuessGame />
//         ) : activeId == 2 &&
//           data?.playStatus === false &&
//           data?.previousRoundPlayed === true &&
//           !loading ? (
//           <Mcqs />
//         ) : activeId == 3 &&
//           data?.playStatus === false &&
//           data?.previousRoundPlayed === true &&
//           !loading ? (
//           <Dashboard />
//         ) : activeId == 4 &&
//           data?.playStatus === false &&
//           data?.previousRoundPlayed === true &&
//           !loading ? (
//           <DoorCOmponent />
//         ) : data?.nextRoundStartTime ? (
//           <NextRound data={data} time={data?.nextRoundStartTime} />
//         ) : (activeId == 0 && data?.previousRoundPlayed === false) ||
//           data?.previousRoundPlayed === false ? (
//           <ErrorPage text={"You didn't played Previous game"} />
//         ) : (
//           <ErrorPage text={"There is no active game to play"} />
//         )
//       }
//     />
//     <Route
//       path="*"
//       element={<ErrorPage text={"There is no active game to play"} />}
//     />
//   </Routes>
// );
