import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";

function UserLeaderBoard() {
  const [leaderBoardData, setLeaderBoardData] = useState([]);

  const [searchParams] = useSearchParams();

  const customerId = searchParams.get("customer_id");

  const GetLeaderBoard = async () => {
    try {
      const id = customerId ? btoa(customerId) : btoa("753982145");
      const res = await axiosInstance.get(`/leaderboard/get`, {
        headers: {
          user_token: id,
        },
      });

      setLeaderBoardData(res?.data?.data);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    GetLeaderBoard();
  }, []);

  return leaderBoardData?.map((dt) => {
    return (
      <div
        className=" d-flex flex-row mb-2 ps-3 rounded-pill align-items-center"
        style={{ background: "#efefef", padding: "8px" }}
      >
        <img
          alt="Image not found"
          src={dt?.user?.image_url}
          width={"40px"}
          height={"40px"}
          className="rounded-5 me-2"
        />

        <div className="d-flex flex-column">
          <p className="m-0">{dt?.user?.username}</p>
          <p className="m-0" style={{ color: "#8B97B4" }}>
            {dt?.user?.email}
          </p>
        </div>
      </div>
    );
  });
}

export default UserLeaderBoard;
