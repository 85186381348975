import React from 'react'

const ModalData = ({ data }) => {
    return (
        <div>
            {data?.data ? data : data}
        </div>
    )
}

export default ModalData
