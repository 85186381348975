import React, { useEffect, useState } from "react";

function Accuracy({ actualPrice, guessedPrice }) {
  const [accuracy, setAccuracy] = useState(0);
  
  const calculateAccuracy = (actualPrice, guessedPrice) => {
    const accuracy =
      100 * (1 - Math.abs(actualPrice - guessedPrice) / actualPrice);
    return Math.max(0, accuracy.toFixed(2)); // Ensuring it doesn't go below 0%
  };

  useEffect(() => {
    if (actualPrice && guessedPrice !== null) {
      setAccuracy(calculateAccuracy(actualPrice, guessedPrice));
    }
  }, [actualPrice, guessedPrice]);

  return (
    <div className="w-100 bg-white rounded-3 p-3 mt-2 text-center">
      <span className="fw-bold fs-6">Accuracy</span>
      <p className="fw-bold fs-1 m-0">{accuracy || 0} %</p>
    </div>
  );
}

export default Accuracy;
