import { createContext, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Navigations from "./Routers/Navigations";

const sessionDataContext = createContext();

function App() {
  const [sessionData, setSessionData] = useState(null);

  return (
    <div className="App">
      <sessionDataContext.Provider value={[sessionData, setSessionData]}>
        <BrowserRouter>
          <Navigations />
          <ToastContainer />
        </BrowserRouter>
      </sessionDataContext.Provider>
    </div>
  );
}

export default App;

export { sessionDataContext };

