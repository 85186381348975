import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import { Col, Row } from "react-bootstrap";

function LeaderBoard() {
  const [leaderBoardData, setLeaderBoardData] = useState([]);

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const customerId = searchParams.get("customer_id");

  const GetLeaderBoard = async () => {
    try {
      const id = customerId ? btoa(customerId) : btoa("753982145");
      const res = await axiosInstance.get(`/leaderboard/get`, {
        headers: {
          user_token: id,
        },
      });

      setLeaderBoardData(res?.data?.data);

      // setLeaderBoardData([
      //   ...res?.data?.data,
      //   ...res?.data?.data,
      //   ...res?.data?.data,
      //   ...res?.data?.data,
      //   ...res?.data?.data,
      // ]);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    GetLeaderBoard();
  }, []);

  const navigateToSpinWheel = async () => {
    try {
      navigate("/spinwheel");
    } catch (e) {
      console.log(e.message);
    }
  };

  const afterThreeRecord = leaderBoardData?.slice(3);

  return (
    <div className="container mt-5 ">
      <Row>
        <Col sm={12}>
          <h1 className="text-center mb-4">Top list</h1>
        </Col>
      </Row>

      <Row className="mb-4 d-flex align-items-center justify-content-center">
        {[0, 1, 2].map(
          (item, index) =>
            leaderBoardData[item] && (
              <Col xs={12} md={4} key={index}>
                <div className="card p-3 card-text h-100">
                  <div className="d-flex align-items-center gap-2 overflow-hidden">
                    <img
                      src={leaderBoardData[item]?.user?.image_url}
                      alt="Image not found"
                      style={{
                        height: "60px",
                        width: "60px",
                        objectFit: "cover",
                        borderRadius: 10,
                      }}
                    />
                    <div className="d-flex flex-column">
                      <div>{leaderBoardData[item]?.user?.name}</div>
                      <div>{leaderBoardData[item]?.user?.email}</div>
                    </div>
                  </div>
                </div>
              </Col>
            )
        )}
      </Row>

      <Row>
        <Col>
          <table className="table championsTable">
            <thead>
              <tr>
                <th className="p-3">Image</th>
                <th className="p-3">Name</th>
                <th className="p-3">Email</th>
                <th className="p-3">Score</th>
                <th className="p-3">Spin</th>
              </tr>
            </thead>

            <tbody>
              {!!afterThreeRecord?.length &&
                afterThreeRecord?.map((item, index) => (
                  <tr key={index} className="text-secondary">
                    <td>
                      <img
                        src={item?.user?.image_url}
                        alt="Image not found"
                        className="userImage"
                      />
                    </td>
                    <td className={"elseUser"}>{item?.user?.name}</td>
                    <td className={"elseUser"}>{item?.user?.email}</td>
                    <td className={"elseUser"}>{item?.score_sum}</td>
                    <td>
                      {item?.user?._id == customerId && (
                        <button
                          className="btn btn-warning"
                          onClick={navigateToSpinWheel}
                        >
                          Spin Wheel
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Col>
      </Row>

      <div></div>
    </div>
  );
}

export default LeaderBoard;
